import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Playlist, PlaylistForPlaylistListFragment } from '@designage/gql';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { Maybe } from 'graphql/jsutils/Maybe';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ConfirmDialogComponent],
  selector: 'app-delete-playlist-dialog',
  template: `<app-confirm-dialog
    [useDangerButton]="true"
    [closeButtonEnabled]="true"
    headerText="DELETE_PLAYLIST"
    closeButtonText="DELETE"
    dismissButtonText="CANCEL"
  >
    <div class="card mb-3">
      <div *ngIf="!isDeleteAllSelected; then thenBlock; else elseBlock"></div>
      <ng-template #thenBlock>
        <h5 class="card-header bg-danger">
          {{ 'DELETE_PLAYLIST_PROMPT' | translate }}
        </h5>
      </ng-template>
      <ng-template #elseBlock>
        <h5 class="card-header bg-danger">
          {{ 'DELETE_PLAYLIST_PROMPT_SELECTED' | translate }}
        </h5>
      </ng-template>

      <div
        *ngIf="activeChannels?.length"
        class="card-body prompt-details-danger"
      >
        <div class="card-title">
          {{ 'PLAYLIST_CHANNEL_REMOVED_PROMPT_DETAILS' | translate }}
          <div class="overflow-auto limit">
            <ul>
              <li *ngFor="let channel of activeChannels">{{ channel }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <ul>
        <li *ngFor="let playlist of selectedPlaylists">{{ playlist?.name }}</li>
      </ul>
    </div>
  </app-confirm-dialog> `,
  styleUrls: ['./delete-playlist-dialog.component.scss'],
})
export class DeletePlaylistDialogComponent {
  playlist?: Maybe<Playlist>;
  name?: Maybe<string>;
  activeChannels?: Maybe<string>;
  isDeleteAllSelected?: boolean;
  selectedPlaylists?: PlaylistForPlaylistListFragment[];
}
