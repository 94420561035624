import { camelCase, startCase } from 'lodash';

// based on https://stackoverflow.com/a/12502559
// output can be predictable but will do just fine for simple operations
export function getRandomString() {
  return Math.random()
    .toString(36)
    .slice(2);
}

export function pascalCase(str: string) {
  return startCase(camelCase(str)).replace(/ /g, '');
}
